/* eslint-disable max-len */
/* eslint-disable max-lines */
import { React } from '@/app'
import { Page, View, Text, Link } from '@/components'
import { Compare } from '@/layout'
import { useApiURL } from '@/utils'

const privacyPolicyHtml = `
  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style>
        body {
          font-family: Arial, sans-serif;
          color: black;
          line-height: 1.6;
        }
        h1, h2, h3 {
          margin-top: 12pt;
          margin-bottom: 12pt;
          font-weight: bold;
        }
        p {
          margin-bottom: 12pt;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 12pt;
        }
        th, td {
          border: 1px solid black;
          padding: 5.75pt;
          text-align: left;
        }
        ul, ol {
          margin-bottom: 12pt;
          padding-left: 20pt;
        }
        a {
          color: blue;
          text-decoration: underline;
        }
        .section {
          margin-bottom: 12pt;
        }
        .section-list {
          margin-left: 20pt;
        }
        .email {
          display: none;
        }
        @media (max-width: 500px) {
          .email {
            display: block;
            margin-top: 8px;
            text-align: center;
          }
        }
      </style>
    </head>
    <body>
      <div class="WordSection1">
        <h1>Privacy Policy for Cherry Dating</h1>
        <p class="section"><strong>This Privacy Policy</strong> is dated December.</p>
        <p class="section">Cherry Dating for Apple iOS and Android (<strong>the app</strong>) is provided by Cherry Dating Ltd trading as ‘Cherry Dating’ (<strong>‘we’, ‘our’ or ‘us’</strong>).</p>
        <p class="section">We take your privacy very seriously. Please read this privacy policy carefully as it contains important information on who we are and how and why we collect, store, use and share any information relating to you (<strong>your information</strong>).</p>
        <p class="section">It also explains your rights in relation to your information and how to contact us or the relevant regulator in the event you have a complaint. Our collection, storage, use and sharing of your information is regulated by law, including under the UK General Data Protection Regulation (<strong>UK GDPR</strong>).</p>
        <p class="section"><strong>If you are aged under 18 you must not use the app as it is not designed for you.</strong> We do not intend to collect the information of anyone under 18. If you are aware that any information of anyone under 18 has been shared with the app, please let us know so that we can delete that data. This version of our privacy policy is written for adults.</p>
        <p class="section">The app is distributed only on UK app stores and is solely intended for use by people in the UK.</p>
        <h2>Agreement to Installation of the App and Processing of Data</h2>
        <p class="section">By downloading the app, you agree to our processing of your personal data (including your name, contact details and device information (where applicable)) as described in the policy. Only download the app if you have read the rules and agree to them.</p>
        <h2>How You Can Withdraw Consent</h2>
        <p class="section">There are a number of different lawful bases upon which we may process your personal data. The relevant lawful bases we rely upon for our use of your personal data are explained below (see “How and why we use your information”). Where we seek your consent to process your personal data, you may change your mind and withdraw consent at any time by either contacting us using the ‘How to Contact Us’ section below or by deleting your account by accessing your account settings in the app, but that will not affect the lawfulness of any processing carried out before you withdraw your consent.</p>
        <h2>This privacy policy is divided into the following sections:</h2>
        <ul>
          <li>Important information about who we are</li>
          <li>What this policy applies to</li>
          <li>Information we collect about you</li>
          <li>Location services/data</li>
          <li>Use of the camera on your device</li>
          <li>Use of the microphone on your device</li>
          <li>How your information is collected</li>
          <li>How and why we use your information</li>
          <li>Marketing</li>
          <li>Who we share your information with</li>
          <li>How long your information will be kept</li>
          <li>Transferring your information out of the UK</li>
          <li>Your rights</li>
          <li>Keeping your information secure</li>
          <li>How to complain</li>
          <li>Changes to this privacy policy</li>
          <li>How to contact us</li>
        </ul>

        <h2>Important information and who we are</h2>
        <p class="section">We are the controller of your information obtained via the app, meaning we are the organisation legally responsible for deciding how and for what purposes it is used.</p>
        <p class="section">We have appointed a data protection officer (DPO). If you have any questions about this privacy policy, please contact them using the ‘How to contact us’ section below. </p>
        <h2>What this policy applies to</h2>
        <p class="section">This privacy policy relates to your use of the app only.</p>
        <h2>Information we collect about you</h2>
        <p class="section">The information we collect about you depends on the particular activities carried out through the app. Most of the information we collect about you is provided to us directly by you (for further information on how we collect data please see the section ‘How your information is collected’ below.</p>
        <p class="section">Sometimes you can choose if you want to give us your information and let us use it. Where that is the case we will tell you and give you the choice before you give the information to us. We will also tell you whether declining to share the information will have any effect on your use of the app or our services.</p>
        <p class="section">We will collect and use the following information about you:</p>
        <table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
          5.9pt;border-collapse:collapse;border:none'>
          <thead>
            <tr style='height:22.5pt'>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
            <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
            style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Category
            of data</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border:solid black 1.0pt;
            border-left:none;padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
            <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
            style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>In
            more detail</span></p>
            </td>
            </tr>
          </thead>
          <tr style='height:.35in'>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Identity and Account Data you
            give us when you register </span></p>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Registration is mandatory in
            order to use the app</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your name, address and contact information, including email
            address and mobile number </span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>information to check and verify your identity, eg date of birth</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your gender</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your account details, such as username and password</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your replies to security questions if applicable </span></p>
            </td>
          </tr>
          <tr style='height:.35in'>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Other Identity and Account Data
            you may give us </span></p>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Please see the ‘Special
            Categories of Data’ section below.</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>sexual orientation</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>ethnicity</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>religious beliefs</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>political opinions </span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your personal or professional interests</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>details of your job title and salary </span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>biometric data </span></p>
            </td>
          </tr>
          <tr style='height:.35in'>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Location Data</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt;height:.35in'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>details of your location with a high degree of precision, see
            the section ‘<b>Location services/data</b>’ below</span></p>
            </td>
          </tr>
          <tr>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Device Data</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>includes the type of mobile device you use, a unique device
            identifier (for example, your Device's IMEI number, the MAC address of the
            Device's wireless network interface, or the mobile phone number used by the
            Device), mobile network information, your mobile operating system, the type
            of mobile browser you use and time zone setting</span></p>
            </td>
          </tr>
          <tr>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Content Data</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>includes information stored on your device, including login
            information, photos, videos or other digital content shared with your consent</span></p>
            </td>
          </tr>
          <tr>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Profile Data </span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>username </span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>preferences</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>information on users you follow or have communicated with</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your direct messages</span></p>
            </td>
          </tr>
          <tr>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Usage Data</span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>includes details of your use of any of our app including, but
            not limited to, traffic data and other communication data, whether this is
            required for our own billing purposes or otherwise and the resources that you
            access</span></p>
            </td>
          </tr>
          <tr>
            <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
            border-top:none;padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
            115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
            font-family:"Arial",sans-serif;color:black'>Marketing and Communications Data
            </span></p>
            </td>
            <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:0in 5.75pt 0in 5.75pt'>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>includes your preferences in receiving marketing from us and our
            third parties and your communication preferences</span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>your contact history (contact between you and us, and between
            you and other users) </span></p>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
            margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
            border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
            color:black'>details of any information, feedback or other matters you give
            to us by phone, email, post or via social media</span></p>
            </td>
          </tr>
          </table>
        <h2>Special Categories of data</h2>
        <p class="section">Certain personal data we collect is treated as a special category to which additional protections apply under data protection law:</p>
        <ul>
          <li>Data revealing racial or ethnic origin, political opinions, religious beliefs, philosophical beliefs</li>
          <li>Biometric data (when used to uniquely identify an individual)</li>
          <li>Data concerning health, sex life, or sexual orientation</li>
          <li>Data concerning your occupation</li>
        </ul>
        <p class="section">Where we process such special category personal data, we will also ensure we are permitted to do so under data protection laws.</p>
        <p class="section">We collect and use your information for the purposes described in the section ‘How and why we use your information’ below. </p>
        <h2>Location services/data</h2>
        <p class="section">The app will request your consent to use location services to precisely identify your location each session. We require access to that data in order to identify real world locations near you to allow us to find other users in your geographical area.</p>
        <p class="section">If you do not provide your agreement, you may use the app but that will mean that we cannot find other users in your geographical area. To withdraw your consent at any time please contact us using the ‘How to contact us’ section below (that will not affect the lawfulness of our use of that data in reliance on the consent before it was withdrawn).</p>
        <p class="section">We will not process your location data other than as strictly required to allow us to find other users in your geographical area.</p>
        <p class="section">The location services in the app will not operate unless location services/data are generally enabled on your device. You may disable such functionality at any time by accessing the location data settings on your device and disabling them. When you allow your device to use location services/data, data will also be collected by the organisations providing the device operating system in accordance with their third party privacy policies.</p>
        <h2>Use of the camera and photo gallery on your device </h2>
        <p class="section">Permission will be requested to use the camera on your device in order to take and upload pictures to your profile. </p>
        <p class="section">You may disable the app’s access to the camera on your device at any time by accessing the camera settings on your device and disabling them, although that will mean that the photo upload feature on the app is no longer available.</p>
        <p class="section">Data gathered by the camera for such purposes remains under your control on the device and we do not have access to it.</p>
        <h2>Use of the device microphone </h2>
        <p class="section">Permission will be requested to use the microphone on your device in order to engage in live audio and video calls over the app. </p>
        <p class="section">You may disable the app’s access to the microphone on your device at any time by accessing the microphone settings on your device and disabling them, although that will mean that the live audio and video call feature on the app is no longer available.</p>
        <p class="section">Data gathered by the microphone for such purposes remains under your control on the device and we do not have access to it.</p>
        <h2>How your information is collected</h2>
        <p class="section">Information you give us</p>
        <p class="section">This is information you give us about you by filling in forms on the app or by corresponding with us (for example, by email or chat). It includes:</p>
        <ul>
          <li>
            Information you provide when you register to use the app. In order to register to use the app, you will need to provide us with your name, phone number, email address,   date      of birth, and gender.
          </li>
          <li>
            Information you choose to provide when you create a profile on the app, such as sexual orientation, ethnicity, religious beliefs, political beliefs, personal and   professional      interests. You may also choose to share additional information such as photos, voice notes, and videos (in order to upload this additional  information, you may allow us      access to your camera and microphone as detailed in the section above).
          </li>
          <li>
            If you contact us via email or social media, we will collect certain information from you, such as Communications, Content, Profile, and Contact Data.
          </li>
          <li>Information you share with us about other users and the direct messages you share.</li>
          <li>Information you share with us about other users in relation to dating preferences.</li>
        </ul>

        <p class="section">Information we collect about you and your device</p>
        <p class="section">Each time you visit our app we will automatically collect personal data including Device Data and Usage Data. We collect this data using cookies and other similar technologies. Please see our Cookie Policy for further details.</p>
        <p class="section">Location Data</p>
        <p class="section">We also use GPS technology to determine your current location. Some of our location-enabled services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by disabling Location Data in your settings,</p>
        <p class="section">Information we receive from other sources including third parties</p>
        <p class="section">We will receive personal data about you from various third parties as set out below:</p>
        <ul>
          <li>
            Device Data from the following parties: analytics providers such as Google based outside the UK and Firebase based outside the UK.
          </li>
          <li>
            Contact, Financial, and Transaction Data from providers of technical, payment, and delivery services.
          </li>
          <li>
            Identity, Contact, and Communications Data from other users when they interact with you or if they report you to us.
          </li>
          <li>
            Identity, Contact, and Profile Data from your other social media accounts should you choose to integrate your Instagram account with our app.
          </li>
        </ul>

        <h2>How and why we use your information</h2>
        <p class="section">Under data protection law, we can only use your information if we have a proper reason, eg:</p>
        <ul>
          <li>Where you have given consent</li>
          <li>To comply with our legal and regulatory obligations</li>
          <li>For the performance of a contract with you or to take steps at your request before entering into a contract</li>
          <li>For our legitimate interests or those of a third party</li>
        </ul>
        <p class="section">A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests. We will carry out an assessment when relying on legitimate interests, to balance our interests against your own. You can obtain details of this assessment by contacting us (see ‘How to contact us’ below).</p>
        <p class="section">The table below explains what we use your information for and why.</p>
        <p class="section">Note that the particular lawful basis on which we process your personal data will depend on the specific purpose for which we are using your data. Please contact us if you need more details about the particular legal basis we are relying on where more than one ground has been set out in the table below. </p>
        <table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
         5.9pt;border-collapse:collapse;border:none'>
         <thead>
          <tr style='height:22.5pt'>
           <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
           padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
           <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
           style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>What
           we use your information for</span></p>
           </td>
           <td width=296 valign=top style='width:222.35pt;border:solid black 1.0pt;
           border-left:none;padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
           <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
           style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
           reasons</span></p>
           </td>
          </tr>
         </thead>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Create and manage your account
          with us</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Necessary to perform our contract
          with you/provide our service to you. We use your information to maintain your
          account and your profile.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data to create and manage you account with us,
          we do so only where you have given explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Providing our dating services
          and/or the functionalities of the app to you</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Depending on the circumstances to
          perform our contract with you or to take steps at your request before
          entering into a contract or for our legitimate interests, i.e. to be as
          efficient as we can so we can deliver the best service to you. By way of
          example, the best service may be by using your information to suggest the
          best data driven romantic match for you. </span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>In relation to the geolocation <i>function
          on the app</i> we will use data relating to your location only based on your
          consent as described in ‘<b>Location services/data</b>’ (above)</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data to provide our dating services and/or the
          functionalities of the app to you, we do so only where you have given
          explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Conducting checks to identify you
          and verify your identity or otherwise to help prevent and detect fraud
          against you or us</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Depending on the circumstances to
          comply with our legal and regulatory obligations or for our legitimate
          interests in order<i> </i>to minimise the risk of account or identity theft
          or fraud that could be damaging for you, a third party or us and to help keep
          our users safe.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data to conduct checks to identify you and
          verify your identity or otherwise to help prevent and detect fraud against
          you or us, we do so only where you have explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To enforce legal rights or defend
          or undertake legal proceedings</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
          to comply with our legal and regulatory obligations or in other cases, for
          our legitimate interests, i.e. to protect our business, interests and rights
          in the event we need to enforce legal rights or defend or undertake legal
          proceedings.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data to enforce legal rights or defend or
          undertake legal proceedings, we do so on the condition it is necessary for
          the establishment, exercise or defence of legal claims. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Communications with you not
          related to marketing, including about changes to our terms or policies or
          changes to the app or service or other important notices or information </span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
          where necessary to perform our contract with you or for our legitimate
          interests, i.e. to provide the best service to you and to ensure you are
          aware of changes that affect you when using our app or service. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To help you connect with other
          users by recommending you to other users and vice versa, showing user
          profiles to one another and enabling users to search and connect with one
          another </span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To perform our contract with
          you/provide our service to you. We use your information to make your profile
          viewable to other users and recommend other users to you and vice versa.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data to help you connect with other users by
          recommending you to other users and vice versa, showing user profiles to one
          another and enabling users to search and connect with one another, we do so
          only where you have given your explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Protect the security of systems
          and data used to provide the app and its services</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To comply with our legal and
          regulatory obligations. </span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>We may also use your information
          to ensure the security of systems and data to a standard that goes beyond our
          legal obligations, and in those cases our reasons are for our legitimate
          interests, i.e. to protect systems and data and to prevent and detect
          criminal activity that could be damaging for you and/or us.</span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Operational reasons, such as
          improving our services on the app and providing new features and services to
          you </span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
          to be as efficient as we can so we can deliver the best service to you<b>.</b></span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data for operational reasons such as improving
          our services on the app and providing new features and services to you, we do
          so only where you have given your explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Statistical analysis to help us
          understand our users </span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
          to be as efficient as we can so we can deliver the best service to you.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data for statistical analysis to help us
          understand our users, we do so only where you have given your explicit
          consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Updating and enhancing customer
          records</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
          to perform our contract with you or to comply with our legal and regulatory
          obligations or where neither of the above apply, for our legitimate
          interests, i.e., making sure we can keep in touch with our users.  </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Disclosures and other activities
          necessary to comply with legal and regulatory obligations, eg to record and
          demonstrate evidence of your consent to our use of your information where
          relevant</span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To comply with our legal and
          regulatory obligations. </span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To the extent we need to process
          special categories of personal data for disclosures and other activities
          necessary to comply with legal and regulatory obligations, we do so either on
          the condition it is necessary for the establishment, exercise or defence of
          legal claims or where you have given your explicit consent. </span></p>
          </td>
         </tr>
         <tr style='height:.35in'>
          <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>To market our services to you and
          measure the effectiveness of advertising campaigns on our app </span></p>
          </td>
          <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt;height:.35in'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
          to promote our app to existing and former users.</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>See ‘<b>Marketing</b>’ below for
          further information.</span></p>
          </td>
         </tr>
        </table>
        <h2>Marketing</h2>
        <p class="section">We will use your information to send you updates (by email or text message) about our app, including exclusive offers, updates or new services.</p>
        <p class="section">We have a legitimate interest in using your information for marketing purposes (see above ‘How and why we use your information’). This means we do not need your consent to send you marketing information. If we change our marketing approach in the future so that consent is needed, we will ask for this separately and clearly.</p>
        <p class="section">You have the right to opt out of receiving marketing communications at any time by:</p>
        <ul>
          <li>Contacting us using the details in the ‘How to contact us’ section</li>
          <li>Using the ‘unsubscribe’ link in emails</li>
        </ul>

        <p class="section">We will always treat your information with the utmost respect and never sell or share it with other organisations for marketing purposes.</p>
        <p class="section">For more information on your right to object at any time to your information being used for marketing purposes, see ‘Your rights’ below.</p>
        <h2>Who we share your information with</h2>
        <p class="section">We routinely share your information with:</p>
        <ul>
          <li>Other users when you disclose information on the service (including your public profile).</li>
          <li>Service providers we use to help us run our business or provide the services or functionalities in the app, such as data hosting and maintenance, analytics, payment        processing, ID verification, marketing, and advertising.</li>
          <li>Apple or Google Stores</li>
        </ul>

        <p class="section">We only allow the companies referred to above to handle your information if we are satisfied they take appropriate measures to protect your information. We also impose contractual obligations on service providers to ensure they can only use your information to provide services to us and to you. </p>
        <p class="section">We or the third parties mentioned above occasionally also need to share your information with:</p>
        <ul>
          <li>
            Our or their external auditors—the recipient of the information will be bound by confidentiality obligations.
          </li>
          <li>
            Our or their professional advisors (such as lawyers and other advisors)—the recipient of the information will be bound by confidentiality obligations.
          </li>
          <li>
            Law enforcement agencies, courts or tribunals, and regulatory bodies to comply with legal and regulatory obligations.
          </li>
          <li>
            Other parties that have or may acquire control or ownership of our business (and our or their professional advisers) in connection with a significant corporate transaction or        restructuring, including a merger, acquisition, asset sale, initial public offering, or in the event of our insolvency—usually, information will be anonymised, but this may        not always be possible. The recipient of any of your information will be bound by confidentiality obligations.
          </li>
        </ul>
        <p class="section">More details about who we share your information with and why are set out below: </p>
        <table class=table border=1 cellspacing=0 cellpadding=0 style='border-collapse:
         collapse;border:none'>
         <tr>
          <td width=199 valign=top style='width:149.45pt;border:solid black 1.0pt;
          padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Recipient</span></p>
          </td>
          <td width=201 valign=top style='width:150.85pt;border:solid black 1.0pt;
          border-left:none;padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Processing operation (use) by
          recipient</span></p>
          </td>
          <td width=201 valign=top style='width:150.5pt;border:solid black 1.0pt;
          border-left:none;padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Relevant categories of your
          information transferred to recipient</span></p>
          </td>
         </tr>
         <tr>
          <td width=199 valign=top style='width:149.45pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Onfido Limited, a company
          incorporated and registered in England and Wales with company number 07479524
          whose registered office is at 14-18 Finsbury Square, 3<sup>rd</sup> Floor,
          London, England EC2A 1AH (‘Onfido’).</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Onfido is a third-party ID
          verification platform. </span></p>
          </td>
          <td width=201 valign=top style='width:150.85pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Captures identity documents and
          facial photographs/video of you for the purpose verifying your identity for
          us. Onfido do this by conducting a:</span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:16.4pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Document Check to assess the likelihood documents provided are
          genuine; and </span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:16.4pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Facial Similarity Check (Selfie) which compares the face
          displayed on a Document with facial image captured of you to verify they are
          the same.</span></p>
          </td>
          <td width=201 valign=top style='width:150.5pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Image(s) of the identity document and information describing the
          identity document</span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Information extracted from the document electronically where
          applicable</span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Image of your face</span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Image of the face in the identity document</span></p>
          <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
          margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
          border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'>Numerical biometric data (special category of data)</span></p>
          </td>
         </tr>
        </table>
        <p class="section">We will not share your information with any other third party.</p>
        <p class="section">If you would like more information about who we share your information with and why, please contact us (see ‘How to contact us’ below).</p>
        <h2>How long your information will be kept</h2>
        <p class="section">Different retention periods apply for different types of your information. We only keep your personal information for as long as we need it for either a legitimate business purpose or as permitted by applicable law. In some circumstances you can ask us to delete your data: see ‘Your rights’ below for further information.</p>
     

        <h2>How to Request Deletion of Your Data</h2>

        <p>At our dating app, we are committed to ensuring transparency and giving you control over your personal information. If you wish to request the deletion of your data, please follow the steps outlined below:</p>

        <h3>1. Deleting Data via the App:</h3>
        <ul>
            <li>Go to <strong>Account Settings</strong> within the app.</li>
            <li>Select the option <strong>Delete account</strong>.</li>
            <li>Follow the provided instructions to confirm your request.</li>
        </ul>

        <h3>2. Deleting Data via Email:</h3>
        <ul>
            <li>Alternatively, you can send an email to <a href="mailto:customerservices@cherrydating.co.uk">customerservices@cherrydating.co.uk</a> with the subject line <strong>"Data Deletion Request"</strong>.</li>
            <li>Include the following details in your email:
                <ul>
                    <li>Full name associated with your account.</li>
                    <li>Email address linked to your account.</li>
                    <li>A brief description of your request.</li>
                </ul>
            </li>
            <li>Our support team will respond to confirm your request and guide you through the next steps.</li>
        </ul>

        <h3>3. Deletion Timeline:</h3>
        <ul>
            <li>Once we receive your request, your data will be deleted within <strong>30 days</strong>, unless retention is necessary to comply with legal obligations, resolve disputes, or maintain the security and integrity of our services.</li>
        </ul>

        <h3>4. What Gets Deleted:</h3>
        <ul>
            <li>All data associated with your account, including your profile, photos, messages, and activity history, will be permanently removed and cannot be recovered.</li>
            <li>Information shared by other users about you (e.g., messages they sent) is not part of your account and will not be deleted.</li>
        </ul>

        <h3>5. Temporary Deactivation:</h3>
        <ul>
            <li>If you do not wish to delete your account permanently but would like to stop using the app temporarily, you can choose to <strong>deactivate your account</strong> in the app settings.</li>
        </ul>

        <p>For further information or assistance regarding the data deletion process, please contact us at <a href="mailto:customerservices@cherrydating.co.uk">customerservices@cherrydating.co.uk</a>. We are here to support you.</p>



        <p class="section">If you choose to delete your account, you may do so at any time through the account settings within the app. Upon initiating account deletion, the following terms apply:</p>
        <p class="section">Data Retention During Deletion Process</p>
        <ul>
          <li>Once a deletion request is submitted, your account will be scheduled for deletion and permanently removed from our systems within 30 days, unless otherwise required by law.        </li>
          <li>During this period, your data will no longer be accessible or used for any purpose.</li>
        </ul>

        <p class="section">Immediate Effects of Deletion</p>
        <ul>
          <li>Upon deletion, your profile, settings, and associated data, including any content shared within the app, will be permanently erased.</li>
          <li>Any connections, interactions, or messages exchanged with other users may remain visible to them but will be anonymized, as applicable.</li>
        </ul>

        <p class="section">Backup and Legal Compliance</p>
        <ul>
          <li>Some data may be retained in backups or logs for a limited period (up to 90 days) to comply with legal requirements, prevent fraud, or resolve disputes.</li>
          <li>Any retained data will be securely stored and deleted once no longer required.</li>
        </ul>

        <p class="section">Third-Party Services</p>
        <ul>
          <li>If you used third-party logins (e.g., Meta, Google) or connected accounts, we recommend reviewing and updating permissions within those platforms as account deletion on        our app does not automatically revoke such permissions.</li>
        </ul>

        <p class="section">Reactivation Policy</p>
        <ul>
          <li>Once the deletion process is completed, your account and data cannot be recovered. You may create a new account but will not have access to any previous data or settings.</        li>
          <li>For assistance with account deletion or if you encounter any issues during the process, please contact our support team at customerservices@cherrydating.co.uk</li>
        </ul>

        <h2>Data Processing Information</h2>
        <p class="section">To ensure transparency and compliance with applicable laws and regulations, including Meta's requirements, we provide the following details about the data we process:</p>
        <p class="section">Types of Data We Process</p>
      <ul>
        <li>Personal Information: Name, email address, phone number, date of birth, gender, and profile pictures.</li>
        <li>Usage Data: IP address, device information (e.g., device type, operating system), and app usage patterns (e.g., pages visited, features used).</li>
        <li>Content Data: Messages, photos, videos, and other content shared within the app.</li>
        <li>Location Data: Approximate or precise location data, if you enable location services.</li>
        <li>Third-Party Data: Information from third-party platforms (e.g., Meta, Google) if you use these services to log in or connect your account.</li>
      </ul>
      <p class="section">How We Process Your Data</p>
      <ul>
        <li>Account Management: To create and manage your account, verify your identity, and provide customer support.</li>
        <li>App Functionality: To enable features such as matching, messaging, and profile customization.</li>
        <li>Improvement and Analytics: To analyze app usage, improve our services, and develop new features.</li>
        <li>Marketing and Communication: To send you promotional offers, updates, and notifications (you can opt out at any time).</li>
        <li>Legal and Security Purposes: To comply with legal obligations, prevent fraud, and ensure the security of our app and users.</li>
      </ul>

      <p class="section">How Users Can Request Deletion of Their Data</p>
      <p class="section">You can request the deletion of your data by following these steps:</p>
      
      <ul>
        <li>Go to your Profile Settings.</li>
        <li>Select Account Settings.</li>
        <li>Choose Delete Account.</li>
      </ul>
      <p class="section">Alternatively, you can contact our support team at customerservices@cherrydating.co.uk to request deletion. We will process your request within 30 days, subject to legal       or operational requirements.</p>


      <p class="section">Data Sharing and Third-Party Access</p>
      <ul>
        <li>Service Providers: Third-party vendors who assist us in operating the app (e.g., hosting, analytics, payment processing).</li>
        <li>Legal Authorities: If required by law or to protect our rights and the safety of our users.</li>
        <li>Third-Party Platforms: If you use third-party logins (e.g., Meta, Google), certain data may be shared with these platforms as per their privacy policies.</li>
      </ul>

      <p class="section">User Rights</p>
      <ul>
        <li>Access: You can request a copy of the data we hold about you.</li>
        <li>Correction: You can update or correct inaccurate data.</li>
        <li>Restriction: You can request that we restrict the processing of your data in certain circumstances.</li>
        <li>Portability: You can request a transfer of your data to another service provider.</li>
        <li>Objection: You can object to the processing of your data for specific purposes, such as marketing.</li>
      </ul>
      <p class="section">To exercise these rights, please contact us at customerservices@cherrydating.co.uk.</p>


        <h2>Transferring your information out of the UK</h2>
        <p class="section">The UK and other countries outside the UK have differing data protection laws, some of which may provide lower levels of protection of privacy.</p>
        <p class="section">It is sometimes necessary for us to transfer your personal data to countries outside the UK. In those cases, we will only do this where we have appropriate measures in place to protect your personal data. </p>
        <p class="section">Your data may be processed by other users of the app that are outside of the UK. This may happen to allow users to contact each other via direct messaging, fulfil a swap, deliver an item, process payment details or provide support services and enable us to run the app.</p>
        <p class="section">Under data protection laws, we can only transfer your personal data to a country outside the UK where:</p>
        <ul>
          <li>in the case of transfers subject to UK data protection law, the UK government has decided the particular country ensures an adequate level of protection of personal data         (known as an ‘adequacy regulation’) further to Article 45 of the UK GDPR. A list of countries the UK currently has adequacy regulations in relation to is available at <a         href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-transfers-a-guide/#adequacy" target="_blank">https://ico.org.uk/        for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-transfers-a-guide/#adequacy</a>.</li>
          <li>there are appropriate safeguards in place, together with enforceable rights and effective legal remedies for you, or</li>
          <li>a specific exception applies under relevant data protection law</li>
        </ul>

        <p class="section">Where we transfer your personal data outside the UK we do so on the basis of an adequacy regulation or (where this is not available) legally-approved standard data protection clauses recognised or issued further to Article 46(2) of the UK GDPR. In the event we cannot or choose not to continue to rely on either of those mechanisms at any time we will not transfer your personal data outside the UK unless we can do so on the basis of an alternative mechanism or exception provided by UK data protection law and reflected in an update to this policy.</p>
        <p class="section">Any changes to the destinations to which we send your information or in the transfer mechanisms we use to transfer your information internationally will be notified to you in accordance with the section on ‘Change to this privacy policy’ below.</p>
        <h2>Your rights</h2>
        <p class="section">You generally have the following rights, which you can usually exercise free of charge:</p>
        <ul>
          <li>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to        check that we are lawfully processing it.</li>
          <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need        to verify the accuracy of the new data you provide to us.</li>
          <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also         have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have         processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply        with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
          <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation         which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are        processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which       override your rights and freedoms.</li>
          <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
            <ul>
              <li>a) if you want us to establish the data's accuracy;</li>
              <li>b) where our use of the data is unlawful but you do not want us to erase it;</li>
              <li>c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
              <li>d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
            </ul>
          </li>
          <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured,        commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the        information to perform a contract with you.</li>
          <li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out        before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at        the time you withdraw your consent.</li>
          <li>You also have the right to ask us not to continue to process your personal data for marketing purposes.</li>
        </ul>
        <h2>Keeping your information secure</h2>
        <p class="section">We have appropriate security measures to prevent your information from being accidentally lost, or used or accessed unlawfully. We limit access to your information to those who have a genuine business need to access it. </p>
        <p class="section">We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</p>
        <h2>How to complain</h2>
        <p class="section">Please contact us if you have any queries or concerns about our use of your information (see below ‘How to contact us’). We hope we will be able to resolve any issues you may have.</p>
        <p class="section">You also have the right to lodge a complaint with the Information Commissioner in the UK </p>
        <p class="section">The Information Commissioner can be contacted using the details at https://ico.org.uk/make-a-complaint or telephone: 0303 123 1113.</p>
        <h2>Changes to this privacy policy</h2>
        <p class="section">We may change this privacy policy from time to time. When we make significant changes we will take steps to inform you, for example via the app or by other means, such as email.</p>
        <h2>How to contact us</h2>
        <p class="section">You can contact us and/or our Data Protection Officer by post, email or telephone if you have any questions about this privacy policy or the information we hold about you, to exercise a right under data protection law or to make a complaint.</p>
        <p class="section">Our contact details are shown below:</p>
        <table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
         5.9pt;border-collapse:collapse;border:none'>
         <thead>
          <tr>
           <td width=297 valign=top style='width:223.0pt;border:solid black 1.0pt;
           padding:0in 5.75pt 0in 5.75pt'>
           <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
           style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
           contact details</span></p>
           </td>
           <td width=296 valign=top style='width:221.9pt;border:solid black 1.0pt;
           border-left:none;padding:0in 5.75pt 0in 5.75pt'>
           <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
           style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
           Data Protection Officer’s contact details</span></p>
           </td>
          </tr>
         </thead>
         <tr>
          <td width=297 valign=top style='width:223.0pt;border:solid black 1.0pt;
          border-top:none;padding:0in 5.75pt 0in 5.75pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Cherry Dating Ltd</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'>
          <span lang="EN-GB" style="font-size:10.0pt;line-height:115%; font-family:'Arial',sans-serif;color:black">71-75 Shelton Street<br>
          Covent Garden<br>London<br>WC2H 9JQ
          </span></p>
          <p class="MsoNormal email-table" style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB><a
          href="mailto:Customerservices@cherrydating.co.uk"><span style='font-size:
          10.0pt;line-height:115%;font-family:"Arial",sans-serif'>customerservices@cherrydating.co.uk</span></a></span><span
          lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
          color:black'> </span></p>
          </td>
          <td width=296 valign=top style='width:221.9pt;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:0in 5.75pt 0in 5.75pt'>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>Johanna Mason</span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>71-75 Shelton Street Covent Garden London WC2H 9JQ</span></p>
          <p class="MsoNormal email-table" style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB><a
          href="mailto:Customerservices@cherrydating.co.uk"><span style='font-size:
          10.0pt;line-height:115%;font-family:"Arial",sans-serif'>customerservices@cherrydating.co.uk</span></a></span></p>
          <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
          115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
          font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
          </td>
         </tr>
        </table>
      </div>
    </body>
  </html>
`

const PrivacyPolicy: React.FC = () => {
	return (
		<Page title='Privacy & Policy' center={true} headerProps={{ white: true }}>
			<View variants={['marginTop:12', 'fullWidth', 'column']}>
				<View variants={['row', 'wrap', 'gap:2', 'marginBottom:2']}>
					<Text variants={['black']}>
						This is the Privacy & Policy Page. Access the Terms & Conditions in
						this link:{' '}
					</Text>
					<Link
						variants={['black', 'underlined']}
						text={'Terms & Conditions'}
						to={'/terms-and-conditions'}
					/>
				</View>
				<View dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
			</View>
		</Page>
	)
}

export default PrivacyPolicy
